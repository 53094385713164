module.exports = {

    devServer: {
        open: true,
        //host: '0.0.0.0',
        https: false,
        hotOnly: false,
        overlay:false,
        proxy: {
            [process.env.VUE_APP_BASE_API]: {
                target: 'http://localhost:8083',
                ws:false,
                secure:false,
                changeOrigin: true,
                pathRewrite: {
                    ['^' + process.env.VUE_APP_BASE_API]:''
                }
            }
        },
    },

}